
import request from '@/utils/request'

// 实时气象数据
export function getMeteorologicalInfo(data) {
  return request({
    url: '/meteorology/getMeteorologicalInfo',
    method: 'post',
    data: data
  })
}
//风力风向监测
export function listWindDirectionInfo(data) {
  return request({
    url: '/meteorology/listWindDirectionInfo',
    method: 'post',
    data: data
  })
}
//光照监测
export function listLightMonitoringInfo(data) {
  return request({
    url: '/meteorology/listLightMonitoringInfo',
    method: 'post',
    data: data
  })
}
//温度监测
export function listTemperatureDataInfo(data) {
  return request({
    url: '/meteorology/listTemperatureDataInfo',
    method: 'post',
    data: data
  })
}
//湿度监测
export function listHumidityDataInfo(data) {
  return request({
    url: '/meteorology/listHumidityDataInfo',
    method: 'post',
    data: data
  })
}
//雨量监测
export function listRainfallDataInfo(data) {
  return request({
    url: '/meteorology/listRainfallDataInfo',
    method: 'post',
    data: data
  })
}
