<template>
  <div class="serviceStation flex pt20px pd1h">
    <div class="">
      <div class="part">
        <itemBg width="28" height="53" title="实时气象数据" />
        <div class="part_con">
          <div class="box_1">
            <div class="title">{{ wdData[0].time }}<span class="t1"> 采集时间</span></div>
            <ul>
              <li>
                <div>
                  <img :src="icon1" alt="温度" />
                </div>
                <div class="t1">{{wdData[0].datas}}<span>{{ wdData[0].translateAddressUnit }}</span></div>
                <div class="t2">{{ wdData[0].translateAddressName }}</div>
              </li>
              <li>
                <div>
                  <img :src="icon2" alt="湿度" />
                </div>
                <div class="t1">{{wdData[1].datas}}<span>{{ wdData[1].translateAddressUnit }}</span></div>
                <div class="t2">{{ wdData[1].translateAddressName }}</div>
              </li>
              <li>
                <div>
                  <img :src="icon3" alt="大气压" />
                </div>
                <div class="t1">{{wdData[2].datas}}<span>{{ wdData[2].translateAddressUnit }}</span></div>
                <div class="t2">{{ wdData[2].translateAddressName }}</div>
              </li>
              <li>
                <div>
                  <img :src="icon4" alt="光照度" />
                </div>
                <div class="t1">{{wdData[3].datas}}<span>{{ wdData[3].translateAddressUnit }}</span></div>
                <div class="t2">{{ wdData[3].translateAddressName }}</div>
              </li>
            </ul>
            <ul>
              <li>
                <div>
                  <img :src="icon5" alt="风速" />
                </div>
                <div class="t1">{{wdData[4].datas}}<span>{{ wdData[4].translateAddressUnit }}</span></div>
                <div class="t2">{{ wdData[4].translateAddressName }}</div>
              </li>
              <li>
                <div>
                  <img :src="icon6" alt="风向" />
                </div>
                <div class="t1">{{wdData[6].datas}}<span>{{ wdData[6].translateAddressUnit }}</span></div>
                <div class="t2">{{ wdData[6].translateAddressName }}</div>
              </li>
              <li>
                <div>
                  <img :src="icon6" alt="风向" />
                </div>
                <div class="t1">{{wdData[7].datas}}<span>{{ wdData[7].translateAddressUnit }}</span></div>
                <div class="t2">{{ wdData[7].translateAddressName }}</div>
              </li>
              <li>
                <div>
                  <img :src="icon7" alt="雨量" />
                </div>
                <div class="t1">{{wdData[5].datas}}<span>mm/h</span></div>
                <div class="t2">{{ wdData[5].translateAddressName }}</div>
              </li>
            </ul>
            <ul>
              <li>
                <div>
                  <img :src="icon7" alt="雨量" />
                </div>
                <div class="t1">{{wdData[8].datas}}<span>{{ wdData[8].translateAddressUnit }}</span></div>
                <div class="t2">{{ wdData[8].translateAddressName }}</div>
              </li>
              <li>
                <div>
                  <img :src="icon9" alt="土湿1" />
                </div>
                <div class="t1">{{wdData[13].datas}}<span>{{ wdData[13].translateAddressUnit }}</span></div>
                <div class="t2">{{ wdData[13].translateAddressName }}</div>
              </li>
              <li>
                <div>
                  <img :src="icon9" alt="土湿2" />
                </div>
                <div class="t1">{{wdData[14].datas}}<span>{{ wdData[14].translateAddressUnit }}</span></div>
                <div class="t2">{{ wdData[14].translateAddressName }}</div>
              </li>
              <li>
                <div>
                  <img :src="icon9" alt="土湿3" />
                </div>
                <div class="t1">{{wdData[15].datas}}<span>{{ wdData[15].translateAddressUnit }}</span></div>
                <div class="t2">{{ wdData[15].translateAddressName }}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="part  mt2h">
        <itemBg width="28" height="35" title="温度数据监测" />
        <div class="part_con">
          <e-charts id="temperatureData" width="28vw" height="35vh" :options="lt"/>
        </div>
      </div>
    </div>
    <div class="">
      <div class="part">
        <itemBg width="41" height="53" title="风力风向监测" />
        <div class="part_con">
          <e-charts id="windDirection" width="41vw" height="49vh" :options="wd"/>
        </div>
      </div>
      <div class="part mt2h">
        <itemBg width="41" height="35" title="雨量数据监测" />
        <div class="part_con">
          <e-charts id="rainfallData" width="41vw" height="35vh" :options="rain"/>
        </div>
      </div>
    </div>
    <div class="">
      <div class="part">
        <itemBg width="28" height="53" title="光照监测" />
        <div class="part_con">
          <e-charts id="lightMonitoring" width="28vw" height="53vh" :options="lt2"/>
        </div>
      </div>
      <div class="part mt2h">
        <itemBg width="28" height="35" title="湿度数据监测" />
        <div class="part_con">
          <e-charts id="humidityData" width="28vw" height="35vh" :options="rb"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import routeChange from '@/mixins/routeChange'
import itemBg from '@/components/itemBg'
import uploadedDataURL from '@/assets/arrow.png'
import icon1 from '@/assets/ic_temperature.png'
import icon2 from '@/assets/ic_humidity.png'
import icon3 from '@/assets/ic_AtmoPres.png'
import icon4 from '@/assets/ic_Illuminance.png'
import icon5 from '@/assets/ic_Customs.png'
import icon6 from '@/assets/ic_WindDirection.png'
import icon7 from '@/assets/ic_Rainfall.png'
import icon8 from '@/assets/ic_HoursSunshine.png'
import icon9 from '@/assets/ic_SoilMoisture.png'
import {
  getMeteorologicalInfo,
  listWindDirectionInfo,
  listTemperatureDataInfo,
  listLightMonitoringInfo,
  listRainfallDataInfo,
  listHumidityDataInfo
} from '@/api/meteorology'
import {
  WINDDIRECTION,
  BAR_OPTINO,
  BAR_OPTINO_TWO,
  LINE_OPTINO_SmoothFalse,
  BAR_OPTINO_3D } from '@/utils/eChartsSetOption'
export default {
  name: '',
  components: { itemBg },
  mixins: [routeChange],
  data() {
    return {
      icon1: icon1,
      icon2: icon2,
      icon3: icon3,
      icon4: icon4,
      icon5: icon5,
      icon6: icon6,
      icon7: icon7,
      icon8: icon8,
      icon9: icon9,
      wdData: [],
      timer: null,
      wd: JSON.parse(JSON.stringify(WINDDIRECTION)),
      lt: JSON.parse(JSON.stringify(BAR_OPTINO)),
      lt2: JSON.parse(JSON.stringify(BAR_OPTINO_TWO)),
      rb: JSON.parse(JSON.stringify(LINE_OPTINO_SmoothFalse)),
      rain: JSON.parse(JSON.stringify(BAR_OPTINO_3D))
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.timer = setInterval(
      this.init, 3000000
    )
    window.addEventListener('resize', () => {
      location.reload()
    })
    // this.setRefurbish()
  },
  methods: {
    maxV(arr) {
      let result = 0
      arr.forEach((item) => {
        if (item > result) {
          result = item
        }
      })
      return result
    },
    init() {
      //实时气象数据
      getMeteorologicalInfo({}).then((res) => {
        this.wdData = res
      })
      //温度数据监测
      listTemperatureDataInfo({}).then((res) => {
        const dataList = []
        res.listWindDirection.forEach((item) => {
          dataList.push(item.datas)
        })
        this.lt.yAxis.name = '温度：℃'
        this.lt.xAxis.axisLabel.textStyle.color = '#fff'
        this.lt.xAxis.axisLabel.rotate = -45
        // this.lt.yAxis.splitLine.lineStyle.type = 'dashed'
        this.lt.yAxis.splitLine.lineStyle.color = 'rgba(87,206,234,0.1)'
        this.lt.xAxis.data = res.times
        this.lt.series[0].data = dataList
        this.lt.grid.left = 40
        this.lt.grid.bottom = 90
        this.lt.series[0].barWidth = '40%'
        this.lt.series[0].itemStyle.color.colorStops[0].color = '#fd377d'
        this.lt.series[0].itemStyle.color.colorStops[1].color = '#3486da'
        this.lt.tooltip.formatter = function (params, ticket, callback) {
          console.log(params, 'params')
          const index = params[0].dataIndex
          const Htm = `${res.times[index]}<br>${dataList[index]}℃ `
          return Htm
        }
      })
      //风力风向监测
      listWindDirectionInfo({}).then((res) => {
        this.wd.series[0].symbol = `image://${uploadedDataURL}`
        this.wd.xAxis.axisLabel.rotate = -45
        const listWindDirectionData = []
        res.listWindDirection.forEach((item) => {
          listWindDirectionData.push(item.datas)
        })
        const listDirectionData = []
        res.listDirection.forEach((item) => {
          listDirectionData.push(item.datas)
        })
        this.wd.xAxis.data = res.times
        this.wd.yAxis.splitLine.lineStyle.color = 'rgba(87,206,234,0.1)'
        this.wd.tooltip.formatter = function (params, ticket, callback) {
          const index = params[0].dataIndex
          const Htm = `${res.times[index]}<br>${listWindDirectionData[index]}m/s `
          return Htm
        }
        this.wd.series[0].data = echarts.util.map(listWindDirectionData, function (item, index) {
          return {
            value: listWindDirectionData[index],
            symbolRotate: listDirectionData[index]
          }
        })
      })
      //光照监测
      listLightMonitoringInfo({}).then((res) => {
        const dataList = []
        res.listWindDirection.forEach((item) => {
          dataList.push(item.datas)
        })
        this.lt2.yAxis[0].data = res.times
        this.lt2.series[0].data = dataList
        this.lt2.yAxis[0].name = '光照：KLux'
        this.lt2.xAxis.splitLine.lineStyle.color = 'rgba(87,206,234,0.1)'
        this.lt2.xAxis.axisLabel.color = '#fff'
      })
      //雨量监测
      listRainfallDataInfo({ code: '0x2e' }).then((res) => {
        //模拟数据
        // const dataList = ['35.2', '10.2', '50.2', '0.0', '2.5', '60.9', '15.3', '0.0', '70', '0.0', '5.5', '18.5']
        // this.rain.xAxis.data = ['00:35','00:35','00:35','00:35', '00:35','00:35','00:35','00:35','00:35','00:35','00:35','00:35']
        // dataList.forEach((item) => {
        //   // dataList.push(item.datas)
        //   maxList.push(maxValue)
        // })
        // const maxList2 = dataList.map((item) => {
        //   return maxValue - item
        // })

        const dataList = []
        const maxList = []
        const maxValue = this.maxV(dataList) + 10
        res.listWindDirection.forEach((item) => {
          dataList.push(item.datas)
          maxList.push(maxValue)
        })
        this.rain.xAxis.data = res.times
        this.rain.yAxis.name = '降水量：mm'
        this.rain.yAxis.max = maxValue
        this.rain.series[0].data = dataList
        this.rain.series[1].data = dataList
        this.rain.series[3].data = dataList
        this.rain.series[2].data = maxList
        this.rain.series[4].data = dataList.map((item) => {
          return maxValue - item
        })
      })
      //湿度监测
      listHumidityDataInfo({}).then((res) => {
        const dataList = []
        res.listWindDirection.forEach((item) => {
          dataList.push(item.datas)
        })
        this.rb.grid.bottom = 90
        this.rb.grid.right = 50
        this.rb.yAxis.name = '湿度℃'
        this.rb.xAxis.data = res.times
        this.rb.series[0].data = dataList
        this.rb.xAxis.axisLabel.textStyle.color = '#fff'
        this.rb.yAxis.splitLine.lineStyle.color = 'rgba(87,206,234,0.1)'
        this.rb.xAxis.axisLabel.rotate = -45
      })
    }
    // setRefurbish() {
    //   setInterval(() => {
    //     this.init()
    //   }, 3000000)
    // }
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  }
}
</script>

<style lang="scss" scoped>
  .part_con{
    width: 100%;
  }
  .box_1{
    width: 100%;
    .title{
      font-size: 40px;
      padding-left: 1vw;
      padding-top: 16px;
      .t1{
        font-size: 24px;
      }
    }
    ul{
      display: flex;
      margin: 0 0 0 0 ;
      padding: 0;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: left;
      +ul{
        background: url("../../assets/SeparatorCharacter_Transversely.png") no-repeat top center;
        background-size: 90% auto;
      }

      li{
        list-style: none;
        width: 7vw;
        height: 13.8vh;
        position: relative;
        text-align: center;
        padding-top: 20px;
        box-sizing: border-box;
        +li{
          background: url("../../assets/SeparatorCharacter_Vertical.png") no-repeat bottom left;
          background-size: auto 11vh;
        }
        img{
          position: absolute;
          top: 0;
          right: 0;
        }
        div{
          img{
            position: static;
            margin-bottom: 10px;
          }
          span{
            font-size: 14px;
            margin-left: 10px;
          }
        }
        .t1{
          font-size: 26px;
        }
        .t2{
          font-size: 16px;
          color: #b8d3f1;
        }
      }
      /*:nth-of-type(2){*/
      /*  li{*/
      /*    background: url("../../assets/SeparatorCharacter_Vertical.png") no-repeat top left;*/
      /*    background-size: auto auto;*/
      /*  }*/
      /*}*/
    }
  }
</style>
